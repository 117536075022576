import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { fetchpairedBatch } from "../DippingParameters/batchSlice";
import { makeStyles } from "@material-ui/core/styles";
// import { visuallyHidden } from '@mui/utils';
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { images } from "../../config/images";
import { ToastContainer, toast } from "react-toastify";
import { deleteCurrentStockData, getCurrentStockData } from "./services";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../components/Button/Button";
import {CsOrgCode,PrimaryUOMCode, SubInventoryCode} from "../../common/Drpdown/CurrentStockDropdowns";

export const CustomStock = (props) => {
  const [customStockData, setCustomeStockData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customDataCount, setCustomDataCount] = useState();
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [filterData, setFilterData] = useState({
    organization_id: "",
    org_code: "",
    inventory_item_id: "",
    item_code: "",
    description: "",
    sub_inventory_code: "",
    primary_uom_code: "",
    current_stock: "",
    locator_id: "",
    item_location: "",
  });

  useEffect(() => {
    const Timer = setTimeout(()=>{
      handleCustomStockData();
    },500)

    return () => clearTimeout(Timer)
   
  }, [filterData, page, rowsPerPage]);

  const handleCustomStockData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    const resp = await getCurrentStockData(filterData, params);
    if (resp.status == 200 || resp.status == 201) {
      setCustomeStockData(resp.data.results);
      setCustomDataCount(resp.data);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeSearch = (e) => {
    console.log("e.target.value",e.target.value)
    console.log("e.target.name",e.target.name)
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  const handleDeleteIcon = async (id) => {
    setDeletePopup(true);
    setDeleteId(id);
  };
  const handleDelete = async () => {
    const resp = await deleteCurrentStockData(deleteId);
    if (
      resp.status == 200 ||
      resp.status == 201 ||
      resp.status == 301 ||
      resp.status == 204
    ) {
      setDeletePopup(false);
      handleCustomStockData();
    } else {
      setDeletePopup(false);
    }
  };
  return (
    <>
      {props.CustomStock ? (
        <>
          <div>
            <ToastContainer />
            <div className="table-responsive pt-2">
              <Table
                aria-label="Dipping parameter"
                className="dipping-parameter-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Organization ID</TableCell>
                    <TableCell>Org Code</TableCell>
                    <TableCell>Inventory Item Id</TableCell>
                    <TableCell>Item Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>SubInventory Code</TableCell>
                    <TableCell>Primary UOM Code</TableCell>
                    <TableCell>Current Stock</TableCell>
                    <TableCell>Location Id</TableCell>
                    <TableCell>Item Location</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Organization ID"
                        name="organization_id"
                        value={filterData.organization_id}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      {/* <input
                        type="search"
                        placeholder="Org Code"
                        name="org_code"
                        value={filterData.org_code}
                        onChange={handleChangeSearch}
                      /> */}
                      <CsOrgCode
                       searchText={filterData.org_code}
                       HandleSearch={handleChangeSearch}
                      />

                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Inventory Item Id"
                        name="inventory_item_id"
                        value={filterData.inventory_item_id}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Item Code"
                        name="item_code"
                        value={filterData.item_code}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Description"
                        name="description"
                        value={filterData.description}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      {/* <input
                        type="search"
                        placeholder="SubInventory Code"
                        name="sub_inventory_code"
                        value={filterData.sub_inventory_code}
                        onChange={handleChangeSearch}
                      /> */}
                      <SubInventoryCode
                       searchText={filterData.sub_inventory_code}
                       HandleSearch={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      {/* <input
                        type="search"
                        placeholder="Primary UOM Code	"
                        name="primary_uom_code"
                        value={filterData.primary_uom_code}
                        onChange={handleChangeSearch}
                      /> */}
                        <PrimaryUOMCode
                       searchText={filterData.primary_uom_code}
                       HandleSearch={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Current Stock	"
                        name="current_stock"
                        value={filterData.current_stock}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Location Id"
                        name="locator_id"
                        value={filterData.locator_id}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      <SearchIcon className="search-icon" />
                      <input
                        type="search"
                        placeholder="Item Location"
                        name="item_location"
                        value={filterData.item_location}
                        onChange={handleChangeSearch}
                      />
                    </TableCell>
                    <TableCell className="search-table-row">
                      
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="reports-table">
                  {customStockData.map((e) => {
                    return (
                      <>
                        <TableRow className="main-table-reports">
                          <TableCell>{e.organization_id}</TableCell>
                          <TableCell>{e.org_code}</TableCell>
                          <TableCell>{e.inventory_item_id}</TableCell>
                          <TableCell>{e.item_code}</TableCell>
                          <TableCell>{e.description}</TableCell>
                          <TableCell>{e.sub_inventory_code}</TableCell>
                          <TableCell>{e.primary_uom_code}</TableCell>
                          <TableCell>{e.current_stock}</TableCell>
                          <TableCell>{e.locator_id}</TableCell>
                          <TableCell>
                            <div className="d-flex justify-content-between gap-5">
                              <div style={{ marginTop: "10px" }}>
                                {e.item_location}
                              </div>
                             
                            </div>
                          </TableCell>
                          <TableCell> <div onClick={() => handleDeleteIcon(e.id)}>
                                <DeleteIcon style={{cursor:"pointer"}}/>
                              </div></TableCell>
                         
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={customDataCount ? customDataCount.count : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className="d-flex justify-content-end aborted">
              <p style={{ margin: 0, marginBottom: 0 }}>
                Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169;
                All Rights Reserved
              </p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div className="sendingdataaaa1">
            <Card className="pairingcard-Approved">
              <CardContent className="p-0 pairing-status">
                <h4>Are you sure you want to delete?</h4>
              </CardContent>
              <Button
                className="pairingcard-btn"
                title="Yes"
                onClick={() => handleDelete()}
              />
              <Button
                className="pairingcard-btn"
                title="No"
                onClick={() => setDeletePopup(false)}
              />
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};
