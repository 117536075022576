import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "../Dashboard/Dashboard.css";
import {getUploadExcelHistory} from "./services"


const Upload_Excel_History = () => {

  const [uploadExcelData,setuploadExcelData] = useState([])
  const [page, setPage] = React.useState(0);
  const [countData, setCountData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterData,setfilterData] = useState({
    
  });
  

 const handle_history = async()=>{
  try{
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    const api  = await getUploadExcelHistory(params)
    if (api.status == 200 || api.status == 201) {
      setuploadExcelData(api.data.results);
      setCountData(api.data.count)
    }
    console.log("api.....",api.data)
  }catch(e){
    console.log("Error in handle_history ",e)
  }
 
 }

 useEffect(() => {
  handle_history();
 },[page,rowsPerPage])

 const handleChangeSearch = (e) => {
  // setFilterData({ ...filterData, [e.target.name]: e.target.value });
  console.log("e.....",e.target.value)
};


const handleChangePage = (event, newPage) => {
  console.log("newPage", newPage);
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};


  return (
    <>
      <div className="dashboard-wrapper page-wraper">
        <ToastContainer />
        <div className="page-header">
          <Link to="/upload-excel" className="page-back-btn">
            <ArrowBackIcon />
            <span>History</span>
          </Link>

        
        </div>
        <div className="table-responsive">
          <Table className="csvReport">
            <TableHead>
              <TableRow>
                <TableCell>SL. No.</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Datasheet type</TableCell>
                <TableCell>Document</TableCell>
                <TableCell>User</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="SL. No." name="SL. No." onChange={handleChangeSearch}/>
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Date" name="Date" onChange={handleChangeSearch}/>
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Time" name="Time" onChange={handleChangeSearch}/>
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Document" name="Document" onChange={handleChangeSearch}/>
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="Document" name="Document" onChange={handleChangeSearch}/>
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input type="search" placeholder="User" name="User" onChange={handleChangeSearch}/>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadExcelData.length
                ? 
                uploadExcelData.map((row, index) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                              {dayjs(row.created_at).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell>
                              {dayjs(row.created_at).format("HH:mm")}
                            </TableCell>
                            <TableCell>
                              {row.excel_type}
                              {" "}
                            </TableCell>
                            <TableCell>
                               <a href={row.excel_file} download>
                                {row.excel_file.split("/").pop()}
                              </a>
                              </TableCell>
                              <TableCell>
                              {row.uploded_by}
                              </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                : ""}
            </TableBody>
          </Table>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={countData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        
      </div>
      <div className="d-flex justify-content-end aborted">
        <p style={{ margin: 0, marginBottom: 0 }}>
          Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169; All
          Rights Reserved
        </p>
      </div>
    </>
  );
};
export default Upload_Excel_History;
