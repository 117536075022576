import React from "react";

const CsOrgCode = ({ searchText, HandleSearch }) => {

    const options = [
        "PBD", "PBG", "PBH", "PBM", "PBO", "PBP", "PBR", "PBT", "PBW",
        "PSA", "PSC", "PSF", "PSP", "PSV"
    ];

    return (
        <>
            <select
                name="org_code"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>Org Code</option>
                <option value="">Select</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        </>
    );
};

const PrimaryUOMCode = ({ searchText, HandleSearch }) => {
    const options = ["EAC", "FT", "KGS", "LTR", "MTR", "PAR", "SET", "SQF"];

    return (
        <>
            <select
                name="primary_uom_code"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>P UOM Code</option>
                <option value="">Select</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}



            </select>
        </>
    );
};

const SubInventoryCode = ({ searchText, HandleSearch }) => {

    const options = [
        "SUBINVENTORY_CODE", "FAS_ST_PBD", "FNS_ST_PBD", "FNS_ST_PBT", "FAS_ST_PBT", "STG_ST_PBD", "FNS_PR_PBD", "MOT_ST_PBT", "STL_YD_PBO",
        "FNS_ST_PSC", "BRG_ST_PBT", "FNS_PR_PBT", "FNS_ST_PBG", "MAM_ST_PBD", "FAS_ST_PBP", "EEM_ST_PBD", "PAT_ST_PBD", "TLS_ST_PBD", "SEL_ST_PBD",
        "PAT_ST_PBT", "BRG_ST_PBD", "FNS_PR_PBG", "FNS_ST_PBP", "FAS_IM_PBD", "CST_YD_PBP", "BRG_ST_PBP", "FNS_ST_PSV", "FAS_ST_PBG", "CST_YD_PBD",
        "MOT_ST_PBG", "ELM_ST_PBD", "PNT_ST_PBD", "FNS_ST_PSP", "FAS_ST_PBR", "FNS_ST_PBW", "MOT_ST_PSP", "SEL_ST_PBT", "STL_YD_PBP", "MOT_ST_PBD",
        "KIT_ST_PBD", "GSC_ST_PBD", "MAM_IM_PBD", "EXP_ST_PBD", "FAS_ST_PSC", "FNS_IM_PBW", "MOT_ST_PSV", "FNS_ST_PSF", "STL_YD_PBD", "SEL_ST_PBP",
        "CST_YD_PBT", "FNS_ST_PSA", "STG_ST_PBO", "STL_YD_PBT", "PAT_ST_PBH", "FNS_PR_PBO", "MOT_ST_PBP", "MOT_ST_PSC", "STG_ST_PBG", "OIL_ST_PBP",
        "BRG_ST_PBG", "OIL_ST_PBD", "RBR_ST_PBG", "STG_ST_PBP", "FAS_ST_PBO", "STG_ST_PBT", "OIL_ST_PBT", "FNS_ST_PBO", "FAS_IM_PBT", "MOT_ST_PSA",
        "TLS_ST_PBG", "ELM_IM_PBD", "EEM_IM_PBD", "FNS_IM_PBD", "FAS_ST_PBW", "BRG_ND_PBP", "FAS_ST_PSP", "RUB_ST_PBT", "ST1_ST_PBT", "FAS_IM_PBW",
        "MOT_ST_PSF", "CST_IM_PBD", "BRG_ST_PBR", "SEL_ST_PBG", "STG_ST_PBR", "PNT_ST_PBG", "PAT_ST_PBP", "REJ_ST_PBD", "FNS_PR_PSV", "FNS_PR_PBP",
        "OIL_ST_PBG", "PAT_ST_PBO", "FNS_PR_PSP", "STG_ST_PBW", "MAM_ST_PBT", "OIL_ST_PSC", "FAS_IM_PBP", "FNS_PR_PSF", "SEM_ST_PBG", "PNT_ST_PBT",
        "ELM_ST_PBT", "PNT_ST_PBP", "STG_ST_PSV", "FNS_PR_PSA", "TLS_IM_PBD", "OIL_ST_PBO", "TLS_ST_PBT", "BRG_ST_PBM", "PAC_ST_PBD", "EEM_ST_PBT",
        "STG_ST_PSp", "FNS_PR_PBW", "FAS_ST_PSF", "OIL_ST_PSA", "OIL_ST_PSF", "FNS_PR_PSC", "MOT_ST_PBW", "MAM_ST_PBG", "ELM_ST_PBG", "OIL_ST_PSP",
        "FNS_IM_PBT", "FAS_ST_PBH", "OIL_ST_PSV", "STG_ST_PSC"
    ];

    return (
        <>
            <select
                name="sub_inventory_code"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >

                <option value="" hidden>SubInventory Code</option>
                <option value="">Select</option>

                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}



            </select>
        </>
    );
};

export { CsOrgCode, PrimaryUOMCode, SubInventoryCode };
