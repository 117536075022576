import {
    Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { fetchpairedBatch } from "../DippingParameters/batchSlice";
import { makeStyles } from "@material-ui/core/styles";
// import { visuallyHidden } from '@mui/utils';
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { images } from "../../config/images";
import { ToastContainer, toast } from "react-toastify";
import { deleteOpenWIPData, getOpenWip } from "./services";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../components/Button/Button";
import { OWClass, OWCompDesc, OWOrgCode, OWStatus, OWcomponent_uom } from "../../common/Drpdown/OpenWipDropdowns";

const OpenWIP = (props) => {
  const [openWipData, setOpenWipData] = useState([]);
  const [openWipDataCount, setOpenWipCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [filterData, setFilterData] = useState({
    org_code: "",
    organization_name: "",
    job: "",
    job_desc: "",
    assembly_id: "",
    assembly_code: "",
    assembly_desc: "",
    status: "",
    class_name: "",
    scheduled_start: "",
    scheduled_complete: "",
    job_start_qty: "",
    comp_id: "",
    component: "",
    comp_desc: "",
    component_uom: "",
    quantity_open: "",
    on_hand_subinv: "",
    quantity_on_hand: "",
    required_quantity: "",
    quantity_issued: "",
  });
  useEffect(() => {
    const Timmer = setTimeout(()=>{
      handleOpenWipData();
    },500)
   return () => clearTimeout(Timmer);

  }, [filterData, page, rowsPerPage]);

  const handleOpenWipData = async () => {
    const params = {
        page: page,
        rowsPerPage: rowsPerPage,
      };
    const resp = await getOpenWip(filterData,params);
    if (resp.status == 200 || resp.status == 201) {
      setOpenWipData(resp.data.results);
      setOpenWipCount(resp.data);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleDeleteIcon = async (id) => {
    setDeletePopup(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    const resp = await deleteOpenWIPData(deleteId);
    if (
      resp.status == 200 ||
      resp.status == 201 ||
      resp.status == 301 ||
      resp.status == 204
    ) {
      setDeletePopup(false);
      handleOpenWipData();
    } else {
      setDeletePopup(false);
    }
  };
  return (
    <>
      {props.OpenWip ? (
        <div>
          <ToastContainer />
          <div className="table-responsive pt-2">
            <Table
              aria-label="Dipping parameter"
              className="dipping-parameter-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Org Code</TableCell>
                  <TableCell>Org Name</TableCell>
                  <TableCell>Job</TableCell>
                  <TableCell>Job Desc</TableCell>
                  <TableCell>Assembly Id</TableCell>
                  <TableCell>Assembly Code</TableCell>
                  <TableCell>Assembly Desc</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Scheduled Start</TableCell>
                  <TableCell>Scheduled Complete</TableCell>
                  <TableCell>Job Start Qty</TableCell>
                  <TableCell>Comp Id</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell>Comp Desc</TableCell>
                  <TableCell>Component Uom</TableCell>
                  <TableCell>Quantity Open</TableCell>
                  <TableCell>On Hand Sub Inv</TableCell>
                  <TableCell>Required Quantity</TableCell>
                  <TableCell>Quantity Issued</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    {/* <input
                      type="search"
                      placeholder="Org Code"
                      name="org_code"
                      value={filterData.org_code}
                      onChange={handleChangeSearch}
                    /> */}
                    <OWOrgCode
                    searchText={filterData.org_code}
                    HandleSearch={handleChangeSearch}/>
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Org Name"
                      name="organization_name"
                      value={filterData.organization_name}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Job"
                      name="job"
                      value={filterData.job}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Job Desc"
                      name="job_desc"
                      value={filterData.job_desc}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Assembly Id"
                      name="assembly_id"
                      value={filterData.assembly_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Assembly Code"
                      name="assembly_code"
                      value={filterData.assembly_code}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Assembly Desc"
                      name="assembly_desc"
                      value={filterData.assembly_desc}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    {/* <input
                      type="search"
                      placeholder="Status"
                      name="status"
                      value={filterData.status}
                      onChange={handleChangeSearch}
                    /> */}
                    <OWStatus
                     searchText={filterData.status}
                     HandleSearch={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    {/* <input
                      type="search"
                      placeholder="Class"
                      name="class_name"
                      value={filterData.class_name}
                      onChange={handleChangeSearch}
                    /> */}

                    <OWClass
                     searchText={filterData.class_name}
                     HandleSearch={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Scheduled Start"
                      name="scheduled_start"
                      value={filterData.scheduled_start}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Scheduled Complete"
                      name="scheduled_complete"
                      value={filterData.scheduled_complete}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Job Start Qty"
                      name="job_start_qty"
                      value={filterData.job_start_qty}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Comp Id"
                      name="comp_id"
                      value={filterData.comp_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Component"
                      name="component"
                      value={filterData.component}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Comp Desc"
                      name="comp_desc"
                      value={filterData.comp_desc}
                      onChange={handleChangeSearch}
                    />
                    
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    {/* <input
                      type="search"
                      placeholder="Component Uom"
                      name="component_uom"
                      value={filterData.component_uom}
                      onChange={handleChangeSearch}
                    /> */}
                    <OWcomponent_uom
                    searchText={filterData.component_uom}
                    HandleSearch={handleChangeSearch}/>
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Quantity Open"
                      name="quantity_open"
                      value={filterData.quantity_open}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="On Hand Sub Inv"
                      name="on_hand_subinv"
                      value={filterData.on_hand_subinv}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Required Quantity"
                      name="required_quantity"
                      value={filterData.required_quantity}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Quantity Issued"
                      name="quantity_issued"
                      value={filterData.quantity_issued}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="reports-table">
                {openWipData.map((e) => {
                  return (
                    <TableRow className="main-table-reports">
                      <TableCell>{e.org_code}</TableCell>
                      <TableCell>{e.organization_name}</TableCell>
                      <TableCell>{e.job}</TableCell>
                      <TableCell>{e.job_desc}</TableCell>
                      <TableCell>{e.assembly_id}</TableCell>
                      <TableCell>{e.assembly_code}</TableCell>
                      <TableCell>{e.assembly_desc}</TableCell>
                      <TableCell>{e.status}</TableCell>
                      <TableCell>{e.class_name}</TableCell>
                      <TableCell>{e.scheduled_start}</TableCell>
                      <TableCell>{e.scheduled_complete}</TableCell>
                      <TableCell>{e.job_start_qty}</TableCell>
                      <TableCell>{e.comp_id}</TableCell>
                      <TableCell>{e.component}</TableCell>
                      <TableCell>{e.comp_desc}</TableCell>
                      <TableCell>{e.component_uom}</TableCell>
                      <TableCell>{e.quantity_open}</TableCell>
                      <TableCell>{e.on_hand_subinv}</TableCell>
                      
                      <TableCell>{e.required_quantity}</TableCell>

                      <TableCell>{e.quantity_issued}</TableCell>
                     
                      <TableCell>
                      <div onClick={() => handleDeleteIcon(e.id)}>
                                <DeleteIcon style={{cursor:"pointer"}}/>
                              </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={openWipDataCount ? openWipDataCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div className="d-flex justify-content-end aborted">
            <p style={{ margin: 0, marginBottom: 0 }}>
              Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169;
              All Rights Reserved
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
       {deletePopup ? (
        <>
          <div className="sendingdataaaa1">
            <Card className="pairingcard-Approved">
              <CardContent className="p-0 pairing-status">
                <h4>Are you sure you want to delete?</h4>
              </CardContent>
              <Button
                className="pairingcard-btn"
                title="Yes"
                onClick={() => handleDelete()}
              />
              <Button
                className="pairingcard-btn"
                title="No"
                onClick={() => setDeletePopup(false)}
              />
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default OpenWIP;
