import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../CustomerReport/CustomerReport.css";
import { DC } from "../CustomerReport/DC";
import BatchTestReport from "../CustomerReport/BatchTestReports";
import { CustomStock } from "./CustomStock";
import OpenWIP from "./OpenWIP";
import { R1Open } from "./R1Open";
import { Link } from "react-router-dom";
import { images } from "../../config/images";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, CardContent } from "@mui/material";
import {
  getCurrentStockData,
  getOpenWip,
  getR1Open,
  uploadExcel,
} from "./services";
import Button from "../../components/Button/Button";

// Define the TabPanel component and its props
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // Render the tab panel
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Define the main component that renders the customer reports tabs
export default function UploadExcelTabs(props) {
  const [value, setValue] = React.useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [popupmessage, setpopupmessage] = useState("");
  const [successmsg, setSuccessmsg] = useState(false);
  // Handle a change in the selected tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("newValue.......", newValue)
  };

  const handleClick = () => {
    setOpenPopup(!openPopup);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setSelectedFilename(event.target.files[0].name);
  };

  const handleUploadpdf = async () => {
    const formData = new FormData();
    formData.append("excel_file", selectedFile);

    // Append the correct excel_type based on the value
    if (value === 0) {
      formData.append("excel_type", "Current Stock");
    } else if (value === 1) {
      formData.append("excel_type", "Open WIP");
    } else if (value === 2) {
      formData.append("excel_type", "Open Shop Order");
    }

    try {
      const resp = await uploadExcel(formData);
      if (resp.status === 200 || resp.status === 201) {
        setSelectedFile(null);
        setpopupmessage(resp.data.message);
        setSelectedFilename("");
        setOpenPopup(false);
        setSuccessmsg(true);
      }
    } catch (error) {
      setSelectedFile(null);
      setpopupmessage(error.response.data.message);
      setSelectedFilename("");
      setSuccessmsg(true);
      setOpenPopup(false);
    }
  };
  const handleCancelClick = () => {
    setOpenPopup(false);
  };
  const Closehandle = () => {
    setSuccessmsg(false);
    setpopupmessage("");
    window.location.reload(false);
  };

  // Render the tabs and their corresponding panels
  return (
    <>
      <div className="page-header d-flex justify-content-between">
        <>
          <Link to="/dashboard" className="page-back-btn">
            <ArrowBackIcon />
            <span>Datasheet</span>
          </Link>

          <div className="button-container-History">
            <Link to="/upload-excel-history" >
              <button
                className={`page-header-btn Upload-History`}

              >
                History
              </button>
            </Link>

            <div onClick={handleClick}>
              <img
                src={images.downloadbtn}
                alt=""
                style={{ height: "50px", cursor: "pointer", transform: "rotate(180deg)" }}
              />
            </div>
          </div>

        </>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box >
          {props.CustomStock && props.OpenWip && props.R1Open && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="box-tabs-table"
            >
              <Tab label="Current stock" className="Current-stock-data" />
              <Tab label="Open WIP" className="openwip-data" />
              <Tab label="Open Shop Order" className="r1open-data" />
            </Tabs>
          )}
        </Box>
        <TabPanel value={value} index={0}>
          <CustomStock
            CustomStock={props.CustomStock}
            handleCustomStock={props.handleCustomStock}
            handleRTRClick={props.handleRTRClick}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OpenWIP
            OpenWip={props.OpenWip}
            handleOpenWip={props.handleOpenWip}
            testReport={props.testReport}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <R1Open
            R1Open={props.R1Open}
            handleR1Open={props.handleR1Open}
            testReport={props.testReport}
          />
        </TabPanel>
      </Box>
      {openPopup && (
        <>
          <div className="sendingdataaaa1"></div>
          <div
            className="sendingData csvReport-data csvReport-popup-box-excel"
            style={{ top: "325px" }}
          >
            <Card
              style={{ padding: "10px", boxShadow: "0px 20px 40px #34343454" }}
            >
              <CardContent>
                <div className="d-flex justify-content-between" required>
                  {value == 0 ? (
                    <h6 style={{ textAlign: "left" }}>Current Stock</h6>
                  ) : value == 1 ? (
                    <h6 style={{ textAlign: "left" }}>Open WIP</h6>
                  ) : (
                    <h6 style={{ textAlign: "left" }}>Open Shop Order</h6>
                  )}

                  <div onClick={handleCancelClick}>
                    <img
                      src={images.closeIcon}
                      className="cancleButton"
                      style={{ marginLeft: "42px" }}
                    />
                  </div>
                </div>

                <div>
                  <div className="file-logo-container">
                    <label
                      htmlFor="fileInput"
                      className="uploadFile uploadFile-button"
                    >
                      <div className="ufile">
                        <span> </span>
                        {selectedFilename ? selectedFilename : "Upload file"}
                      </div>
                    </label>
                    <img
                      className="file-upload-icon-excel"
                      src={images.uploadIcon}
                    />
                  </div>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    required
                  />

                  <p
                    style={{
                      fontSize: "11px",
                      textAlign: "left",
                      marginTop: "5px",
                    }}
                  >
                    <b>Note:</b> Upload .xlsx file only.
                  </p>
                </div>
              </CardContent>
              <button
                style={{
                  width: "250px",
                  marginBottom: "20px",
                  marginTop: "-27px",
                }}
                onClick={handleUploadpdf}
              >
                Upload
              </button>
            </Card>
          </div>
        </>
      )}
      {successmsg ? (
        <>
          <div className="sendingdataaaa1">
            <Card className="pairingcard-Approved">
              <CardContent className="p-0 pairing-status">
                <h4>{popupmessage ? popupmessage : "message not get..."}</h4>
              </CardContent>
              <Button
                className="pairingcard-btn"
                title="Close"
                onClick={() => {
                  Closehandle();
                }}
              />
            </Card>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
