import { request } from "../../api/api";

export const getCurrentStockData = (filterData, params) => {
  return request.get(
    `/getCurrentStockData/?organization_id=${
      filterData.organization_id
    }&org_code=${filterData.org_code}&inventory_item_id=${
      filterData.inventory_item_id
    }&item_code=${filterData.item_code}&description=${
      filterData.description
    }&sub_inventory_code=${filterData.sub_inventory_code}&primary_uom_code=${
      filterData.primary_uom_code
    }&current_stock=${filterData.current_stock}&locator_id=${
      filterData.locator_id
    }&item_location=${filterData.item_location}&page=${
      params.page + 1
    }&page_size=${params.rowsPerPage}`
  );
};

export const getOpenWip = (filterData, params) => {
  return request.get(
    `/getOpenWIPData/?org_code=${filterData.org_code}&organization_name=${
      filterData.organization_name
    }&job=${filterData.job}&job_desc=${filterData.job_desc}&assembly_id=${
      filterData.assembly_id
    }&assembly_code=${filterData.assembly_code}&assembly_desc=${
      filterData.assembly_desc
    }&status=${filterData.status}&class_name=${
      filterData.class_name
    }&scheduled_start=${filterData.scheduled_start}&scheduled_complete=${
      filterData.scheduled_complete
    }&job_start_qty=${filterData.job_start_qty}&comp_id=${
      filterData.comp_id
    }&component=${filterData.component}&comp_desc=${
      filterData.comp_desc
    }&component_uom=${filterData.component_uom}&quantity_open=${
      filterData.quantity_open
    }&on_hand_subinv=${filterData.on_hand_subinv}&quantity_on_hand=${
      filterData.quantity_on_hand
    }&required_quantity=${filterData.required_quantity}&quantity_issued=${
      filterData.quantity_issued
    }&page=${params.page + 1}&page_size=${params.rowsPerPage}`
  );
};

export const getR1Open = (filterData, params) => {
  return request.get(
    `/getR1OpenSOData/?org_id=${filterData.org_id}&name=${filterData.name}&order_number=${filterData.order_number}&ordered_date=${filterData.ordered_date}&header_id=${filterData.header_id}&line_id=${filterData.line_id}&line_number=${filterData.line_number}&shipment_number=${filterData.shipment_number}&inventory_item_id=${filterData.inventory_item_id}&item_code=${filterData.item_code}&ordered_quantity=${filterData.ordered_quantity}&ship_from_org_id=${filterData.ship_from_org_id}&org_name=${filterData.org_name}&flow_status_code=${filterData.flow_status_code}&page=${params.page + 1}&page_size=${params.rowsPerPage}`
  );
};

export const uploadExcel = (params)=>{
    return request.post(`/uploadExcel/`,params)
}

export const deleteCurrentStockData =(id)=>{
    return request.delete(`/deleteCurrentStockData/${id}`)
}

export const deleteOpenWIPData =(id) =>{
    return request.delete(`/deleteOpenWIPData/${id}/`)
}
export const deleteR1OpenSOData =(id) =>{
    return request.delete(`/deleteR1OpenSOData/${id}/`)
}

export const getUploadExcelHistory = (params)=>{
  return request.get(`/getUploadExcelHistory/?page=${params.page + 1}&page_size=${params.rowsPerPage}`)
}