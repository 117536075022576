import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { fetchpairedBatch } from "../DippingParameters/batchSlice";
import { makeStyles } from "@material-ui/core/styles";
// import { visuallyHidden } from '@mui/utils';
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { images } from "../../config/images";
import { ToastContainer, toast } from "react-toastify";
import { deleteR1OpenSOData, getR1Open } from "./services";
import { Tab } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../components/Button/Button";
import { FlowStatusCode } from "../../common/Drpdown/R1OpenDropdowns";

export const R1Open = (props) => {
  const [r1OpenData, setR1OpenData] = useState([]);
  const [r1OpenCount, setR1OpenCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [filterData, setFilterData] = useState({
    org_id: "",
    name: "",
    order_number: "",
    ordered_date: "",
    header_id: "",
    line_id: "",
    line_number: "",
    shipment_number: "",
    inventory_item_id: "",
    item_code: "",
    ordered_quantity: "",
    ship_from_org_id: "",
    org_name: "",
    flow_status_code: "",
  });

  useEffect(() => {
    const Timmer = setTimeout(()=>{
      handleR1OpenData();
    },500)

    return ()=> clearTimeout(Timmer);
   
  }, [filterData, page, rowsPerPage]);

  const handleR1OpenData = async () => {
    const params = {
      page: page,
      rowsPerPage: rowsPerPage,
    };
    const resp = await getR1Open(filterData, params);
    if (resp.status == 200 || resp.status == 201) {
      setR1OpenData(resp.data.results);
      setR1OpenCount(resp.data);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeSearch = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  const handleDeleteIcon = async (id) => {
    setDeletePopup(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    const resp = await deleteR1OpenSOData(deleteId);
    if (
      resp.status == 200 ||
      resp.status == 201 ||
      resp.status == 301 ||
      resp.status == 204
    ) {
      setDeletePopup(false);
      handleR1OpenData();
    } else {
      setDeletePopup(false);
    }
  };
  return (
    <>
      {props.R1Open ? (
        <div>
          <ToastContainer />
          <div className="table-responsive pt-2">
            <Table
              aria-label="Dipping parameter"
              className="dipping-parameter-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Org ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Order Number</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Header Id</TableCell>
                  <TableCell>Line Id</TableCell>
                  <TableCell>Line Number</TableCell>
                  <TableCell>Shipment Number</TableCell>
                  <TableCell>Inventory Item Id</TableCell>
                  <TableCell>Item Code</TableCell>
                  <TableCell>Order Quantity</TableCell>
                  <TableCell>Ship From Org Id</TableCell>
                  <TableCell>Org Name</TableCell>
                  <TableCell>Flow Status Code</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Org ID"
                      name="org_id"
                      value={filterData.org_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Name"
                      name="name"
                      value={filterData.name}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Order Number"
                      name="order_number"
                      value={filterData.order_number}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Order Date"
                      name="ordered_date"
                      value={filterData.ordered_date}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Header Id"
                      name="header_id"
                      value={filterData.header_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Line Id"
                      name="line_id"
                      value={filterData.line_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Line Number"
                      name="line_number"
                      value={filterData.line_number}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Shipment Number"
                      name="shipment_number"
                      value={filterData.shipment_number}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Inventory Item Id"
                      name="inventory_item_id"
                      value={filterData.inventory_item_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Item Code"
                      name="item_code"
                      value={filterData.item_code}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Order Quantity"
                      name="ordered_quantity"
                      value={filterData.ordered_quantity}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Ship From Org Id"
                      name="ship_from_org_id"
                      value={filterData.ship_from_org_id}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Org Name"
                      name="org_name"
                      value={filterData.org_name}
                      onChange={handleChangeSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    {/* <input
                      type="search"
                      placeholder="Flow Status Code"
                      name="flow_status_code"
                      value={filterData.flow_status_code}
                      onChange={handleChangeSearch}
                    /> */}
                    <FlowStatusCode 
                    searchText={filterData.flow_status_code}
                    HandleSearch={handleChangeSearch}/>
                  </TableCell>
                  <TableCell className="search-table-row">
                   
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="reports-table">
                {r1OpenData.map((e) => {
                  return (
                    <TableRow className="main-table-reports">
                      <TableCell>{e.org_id}</TableCell>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{e.order_number}</TableCell>
                      <TableCell>{e.ordered_date}</TableCell>
                      <TableCell>{e.header_id}</TableCell>
                      <TableCell>{e.line_id}</TableCell>
                      <TableCell>{e.line_number}</TableCell>
                      <TableCell>{e.shipment_number}</TableCell>
                      <TableCell>{e.inventory_item_id}</TableCell>
                      <TableCell>{e.item_code}</TableCell>
                      <TableCell>{e.ordered_quantity}</TableCell>
                      <TableCell>{e.ship_from_org_id}</TableCell>
                      <TableCell>{e.org_name}</TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-between gap-5">
                          <div style={{ marginTop: "10px" }}>
                            {e.flow_status_code}
                          </div>
                         
                        </div>
                      </TableCell>
                      <TableCell>
                      <div onClick={() => handleDeleteIcon(e.id)}>
                            <DeleteIcon style={{ cursor: "pointer" }} />
                          </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={r1OpenCount ? r1OpenCount.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div className="d-flex justify-content-end aborted">
            <p style={{ margin: 0, marginBottom: 0 }}>
              Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169;
              All Rights Reserved
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div className="sendingdataaaa1">
            <Card className="pairingcard-Approved">
              <CardContent className="p-0 pairing-status">
                <h4>Are you sure you want to delete?</h4>
              </CardContent>
              <Button
                className="pairingcard-btn"
                title="Yes"
                onClick={() => handleDelete()}
              />
              <Button
                className="pairingcard-btn"
                title="No"
                onClick={() => setDeletePopup(false)}
              />
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};
