import React from 'react';

const FlowStatusCode = ({ searchText, HandleSearch })=>{
    const options = ["AWAITING_SHIPPING","SHIPPED"];

    return(
       
        <>
        <select
            name="flow_status_code"
            className="select_print_result"
            value={searchText}
            onChange={HandleSearch}
        >
            <option value="" hidden>Flow Status Code</option>
            <option value="">Select</option>
            {options.map(option => (
                <option key={option} value={option}>{option}</option>
            ))}

        </select>
    </>
    )
}

export {FlowStatusCode};