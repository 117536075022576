import { useEffect } from "react";
import { saveAs } from 'file-saver';
import { randomBytes } from 'crypto';


 const generateUUIDWithTimestamp = () => {
    // Generate a random buffer
    const buffer = randomBytes(8); // 8 bytes = 16 hex characters
  
    // Convert buffer to hexadecimal string
    const uuidSegment = buffer.toString('hex').toUpperCase();
  
    // Get current timestamp in milliseconds and take the last 8 characters
    const timestamp = Date.now().toString(16).slice(-8).toUpperCase();
  
    // Combine timestamp with UUID segment and ensure length is 16 characters
    return `${uuidSegment}${timestamp}`.slice(0, 16);
  };


const RandomCSV = () => {

  useEffect(() => {
    downloadCsv();
  },[])

  const downloadCsv = () => {
    const csvHeader = "UID\n";
    const values = Array.from({ length: 50 }, generateUUIDWithTimestamp);
    const csvContent = values.map(value => value).join("\n");
    const csvFile = csvHeader + csvContent;
    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "random_hex_values.csv");
  };

  return 1
}

export default RandomCSV