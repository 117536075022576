import React from "react";

const OWOrgCode = ({ searchText, HandleSearch }) => {

    const options = ["PBD", "PBG", "PBO", "PBP", "PBT", "PBW", "PSA", "PSC", "PSF", "PSP", "PSV"];

    return (
        <>
            <select
                name="org_code"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>Org Code</option>
                <option value="">Select</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}

            </select>
        </>
    );
};

const OWClass = ({ searchText, HandleSearch }) => {
    const options = [
        "DAVIDBROWN",
        "OPEN GEAR",
        "PBG-STD",
        "PBP-STD",
        "PBW-STD",
        "PSA_STD",
        "PSC_STD",
        "PSF_STD",
        "PSP_STD",
        "PSV_STD",
        "TRANS"
    ];
    return (
        <>
            <select
                name="class_name"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>Class</option>
                <option value="">Select</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}

            </select>
        </>
    );
};

const OWcomponent_uom = ({ searchText, HandleSearch }) => {

    const options = ["EAC", "KGS", "LTR", "MTR", "SET"];


    return (
        <>
            <select
                name="component_uom"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>Comp Desc</option>
                <option value="">Select</option>
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}


            </select>
        </>
    );
};


const OWStatus = ({ searchText, HandleSearch }) => {
    return (
        <>
            <select
                name="status"
                className="select_print_result"
                value={searchText}
                onChange={HandleSearch}
            >
                <option value="" hidden>Status</option>
                <option value="">Select</option>
                <option value="Released">Released</option>

            </select>
        </>
    );
};

export { OWOrgCode, OWClass, OWcomponent_uom, OWStatus };
