import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomerReportsTabs from "./Tabs";
import UploadExcelTabs from "./Tabs";
import { images } from "../../config/images";
import { Box, Card, CardContent } from "@mui/material";

function UploadMainReport() {
  const [CustomStock, setCustomStock] = useState(true);
  const [OpenWip, setOpenWip] = useState(true);
  const [R1Open, setR1Open] = useState(true);
  const [viewReport, setViewReport] = useState(true);
  const [testReport, setTestReport] = useState(true);


  // Handle click event for back button
  const handleBack = () => {
    setCustomStock(true);
    setOpenWip(true);
    setViewReport(true);
    setTestReport(true);
    setR1Open(true);
  };

  // Handle click event for DC button
  const handleDcClick = () => {
    setCustomStock(false);
    setTestReport(false);
    setR1Open(false);
  };

  // Handle click event for RTR button
  const handleRTRClick = () => {
    setOpenWip(false);
    setCustomStock(false);
    setTestReport(false);
    setR1Open(false);
  };

  // Handle click event for View Batch Report button
  const handleViewBatchReport = () => {
    setViewReport(false);
    setTestReport(false);
    setR1Open(false);
  };


  return (
    <>
      <div className="dashboard-wrapper page-wraper">
      
        <UploadExcelTabs
          CustomStock={CustomStock}
          handleDcClick={handleDcClick}
          OpenWip={OpenWip}
          R1Open={R1Open}
          handleRTRClick={handleRTRClick}
          handleViewBatchReport={handleViewBatchReport}
          viewReport={viewReport}
          testReport={testReport}
        />
      </div>
   
    </>
  );
}
export default UploadMainReport;
